import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import YoutubeEmbed from "../tools.component/youtubeEmbed.component";
import mysqlServices from "../../services/mysqlServices";
function Videos(params) {
    const canLoad = useRef(true);
    const { t, i18n } = useTranslation();
    const [videos, setVideos] = useState(null);
    useEffect(() => {
        if (canLoad.current) {
            mysqlServices.getVideos().then(data => {
                //console.log(data.data)
                setVideos(data.data);
            }).catch(err => {
                console.log(err);
            })
        } else {

        }
    }, [])

    return (
        <div className='parent-container-x-margins margin-top-2-rem margin-bottom-1-rem display-flex flex-direction-column flex-wrap  min-width-15-rem row-gap-2-rem column-gap-2-rem overflow-y-hidden' style={{ minHeight: 'calc(100vh - 100px)' }}>

            {(videos && videos.length > 0) ? videos.map((item, index) =>



                <div className="video-custom-container display-flex flex-direction-column coloumn-gap-0-5-rem row-gap-0-5-rem">
                    <span className='font-size-20-px bold'>{i18n.language === "fr" ? item.header_french : item.header_english}</span>
                    <div className="">

                        {item.type === "youtube" ?
                            <YoutubeEmbed title={i18n.language === "fr" ? item.header_french : item.header_english} embedId={item.src} videoStyle="video-responsive" />
                            :
                            item.type === "vimeo" ?
                                <span>
                                    <iframe src={item.src} title="vimeo video" className="video-responsive" height="auto" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                                </span>
                                : null
                        }
                    </div>

                    <span className=''>{i18n.language === "fr" ? item.paragraph_french : item.paragraph_english}</span>
                </div>
            ) : 
                <span className='no-videos-message'>No videos to show yet</span>
            }

        </div>
    )
}
export default Videos;