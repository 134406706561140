import React, { useEffect, useState, useRef } from "react";

// import { HashLink as LinkTo } from "react-router-hash-link";

import Logo from "../../assets/images/logos/final logo.png";
import { FiFacebook, FiTwitter, FiInstagram, FiLayers } from "react-icons/fi";
import { AiOutlineMail, AiOutlineHome, AiFillLinkedin } from "react-icons/ai";
import { TbMinusVertical } from "react-icons/tb";
import { TbMap2 } from 'react-icons/tb'
import { BsChatDots } from "react-icons/bs";
import "./header.css";
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import cookies from 'js-cookie';
import { TbUserExclamation } from "react-icons/tb";

import Selection from "../tools.component/selection.component";

import { useLocation } from 'react-router-dom';
// import GA4React, { useGA4React } from "ga-4-react";
import { CgErase } from "react-icons/cg";


function Header(props) {
    const minimumWindowSize = 768;
    const [stickyHeader, setStickyHeader] = useState(false);
    const lastScrollPosition = useRef(0);

    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const [language, setLanguage] = useState('en');
    const navigate = useNavigate();
    let location = useLocation();
    const [selectedPage, setSelectedPage] = useState(window.location.href.split('/').slice(-1)[0]);

    const { t, i18n } = useTranslation();
    let lan = window?.Cookies?.get('i18next');
    const currentLanguageCode = localStorage.getItem('i18nextLng') || lan || 'en';
    const ref = useRef(true);

    const resourcesSelectionOptions = [{ "name": "What is FGM/C?", "link": "/resources/fgm" }, { "name": "What is GBV?", "link": "/resources/gbv" }, { "name": "toolkit", "link": "/resources/toolkit" }, { "name": "digital-stories", "link": "/resources/digital-stories" }, { "name": "videos", "link": "/resources/videos" }, { "name": "podcast", "link": "/resources/podcast" }, { "name": "webinars", "link": "/resources/webinars" }, { "name": "additional-information", "link": "/resources/additional-information" }];
    const headerSelectionOptions = [{ "name": "home", "link": "/" }, { "name": "about", "link": "/about" }, { "name": "map", "link": "/map" }, { "name": "resources", "link": "/resources/fgm" }, { "name": "contact", "link": "/contact" }, { "name": "quit", "link": "/google.ca" }];

    const [resourcesOpen, setResourcesOpen] = useState(false);
    const [currentResourcesValue, setCurrentResourcesValue] = useState("");

    const [headerOpen, setHeaderOpen] = useState(false);
    const [headerCurrentValue, setHeaderCurrentValue] = useState("home");

    // const ga = useRef("");
    // const ga4react = new GA4React(process.env.REACT_APP_GOOGLE_ANALYTICS_TRAKING_CODE);

    const handleScroll = (e) => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop;
        if (winScroll <= lastScrollPosition.current || winScroll < 20) {
            setStickyHeader(true);

        } else {
            setStickyHeader(false);
        }

        lastScrollPosition.current = winScroll;
    };
    const handleResize = () => {
        setWindowSize(window.innerWidth);
    }
    useEffect(() => {
        //window.addEventListener("scroll", handleScroll);
        console.log(currentLanguageCode);
        if (ref.current) {
            // console.log(currentLanguageCode)
            // console.log(selectedPage)
            ref.current = false;
            setLanguage(currentLanguageCode);
            // setGA()
            // setSelectedPage(window.location.href.split('/').slice(-1)[0]);
        }
        window.addEventListener("resize", handleResize);

        return () => {
            //    window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // useEffect(()=>{
    //     // console.log("==============================================")
    //     // console.log(">>>>>>",location.pathname)
    //     // ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    //     if(ga.current!==""){
    //         // console.log(ga.current);
    //         ga.current.gtag('event', 'pageview', window.location.pathname + window.location.search)
    //     }
    // },[location])

    const changeLanguage = (ln) => {
        if (language === ln) {
            return;
        }
        setLanguage(ln);
        console.log(ln)
        i18n.changeLanguage(ln);
    }

    // function handleNavigate(page){
    //     navigate(`/${page}`)
    //     handleResourcesOpen(false);
    //     //setCurrentValue("")
    //     setSelectedPage(page.split("/")[0])
    // }

    function handleNavigateHeader(page) {
        handleResourcesOpen(false);
        //setCurrentValue("")
        setSelectedPage(page.split("/")[0])
        // setTimeout(()=>{
        //     // we need some time to allow the page to load
        //     console.log(window.location.pathname + window.location.search)
        //     ReactGA.pageview(window.location.pathname + window.location.search);
        // }, 20)

    }

    const handleResourcesOpen = (state) => {
        setResourcesOpen(state);
    };

    const handleHeaderOpen = (state) => {
        setHeaderOpen(state);
    };

    const handleResourcesSelectionChange = (item) => {
        // navigate(`/resources/${item}`)
        setSelectedPage('resources')
        // setTimeout(() => {
        //     // we need some time to allow the page to load
        //     ReactGA.pageview(window.location.pathname + window.location.search);
        // }, 20)
    }

    const handleHeaderSelectionChange = (item) => {
        // if(item==="resources"){
        //     navigate(`/${item}/"toolkit"`);
        // }
        // else 
        // if (item==="quit"){
        //     handleQuit()
        // }
        // } else if (item === "home") {
        //     navigate(`/`);
        // }
        // else{
        //     navigate(`/${item}`);
        // }
        setSelectedPage(item);
        setHeaderCurrentValue(item);
        setHeaderOpen(false);
    }



    // const setGA = async () => {
    //     console.log("analytics", process.env.REACT_APP_GOOGLE_ANALYTICS_TRAKING_CODE, window.location.pathname + window.location.search)
    //     await ga4react.initialize().then((ga4) => {
    //         console.log(ga4)
    //         // ga4.pageview(window.location.pathname + window.location.search)
    //         ga4.gtag('event', 'pageview', window.location.pathname + window.location.search) // or your custom gtag event
    //         ga.current = ga4
    //     }).catch(err => {
    //         console.log(err);
    //     })

    // }


    return (<>
        <div className={`Header ${stickyHeader ? "sticky" : ""}`}>

            <div className="Header-Logo">
                <img
                    src={Logo}
                    alt="wematter Logo"
                    className="Header-Logo-Image"
                />
            </div>

            <div className="display-flex flex-wrap column-gap-2-rem row-gap-2-rem">
                <Link to={"/"} className={`text-decoration-none ${selectedPage==="home" ? "Selected-Header-Button" : ""}`}>
                    <div className={`hover-underline-animation`} alt="map button" key={"map button"} onClick={() => { setSelectedPage("home") }}>
                        <span className="color-black text-decoration-none " key={"2map button"}>{t('Home')}</span>
                    </div>
                </Link>
                <Link to={"/map"} className={`text-decoration-none ${selectedPage === "map" ? "Selected-Header-Button" : ""}`}>
                    <div className={`hover-underline-animation`} alt="map button" key={"map button"} onClick={() => { setSelectedPage("map") }}>
                        <span className="color-black text-decoration-none " key={"2map button"}>{t('Map')}</span>
                    </div>
                </Link>
                <Link to={"/get-involved"} className={`text-decoration-none ${selectedPage === "get-involved" ? "Selected-Header-Button" : ""}`}>
                    <div className={`hover-underline-animation`} alt="get involved button" key={"get involved button"} onClick={() => { setSelectedPage("get-involved") }}>
                        <span className="color-black text-decoration-none " key={"2map button"}>{t('Get Involved')}</span>
                    </div>
                </Link>
                <Link to={"/about-us"} className={`text-decoration-none ${selectedPage === "about-us" ? "Selected-Header-Button" : ""}`}>
                    <div className={`hover-underline-animation`} alt="map button" key={"map button"} onClick={() => { setSelectedPage("about-us") }}>
                        <span className="color-black text-decoration-none " key={"2map button"}>{t('About Us')}</span>
                    </div>
                </Link>
                <Link to={"/contact-us"} className={`text-decoration-none ${selectedPage === "contact-us" ? "Selected-Header-Button" : ""}`}>
                    <div className={`hover-underline-animation`} alt="map button" key={"map button"} onClick={() => { setSelectedPage("contact-us") }}>
                        <span className="color-black text-decoration-none " key={"2map button"}>{t('Contact Us')}</span>
                    </div>
                </Link>
                <Link to={"/videos"} className={`text-decoration-none ${selectedPage === "videos" ? "Selected-Header-Button" : ""}`}>
                    <div className={`hover-underline-animation`} alt="videos button" key={"videos button"} onClick={() => { setSelectedPage("videos") }}>
                        <span className="color-black text-decoration-none " key={"2videos button"}>{t('Videos')}</span>
                    </div>
                </Link>
            </div>

            <div className="display-flex column-gap-2-rem row-gap-2-rem flex-wrap">


                <div className="Language-Icons-Container">
                    <div className="Language-Selection">
                        <div className={`Language-Option ${language === 'en' ? "Selected-Language" : ""} cursor-pointer font-size-16-px`} onClick={() => { changeLanguage('en') }}>English</div>
                        <div className="Separator" alt="separator">
                            <TbMinusVertical />
                        </div>
                        <div className={`Language-Option ${language === 'fr' ? "Selected-Language" : ""} cursor-pointer font-size-16-px`} onClick={() => { changeLanguage('fr') }}>Français</div>
                    </div>
                    <div className="Header-Icons">
                        <div className="Header-Icon" alt="facebook button" onClick={() => window.open("https://www.facebook.com")}>
                            <FiFacebook />
                        </div>
                        <div className="Header-Icon" alt="twitter button" onClick={() => window.open("https://www.twitter.com")}>
                            <FiTwitter />
                        </div>
                        <div className="Header-Icon" alt="instagram button" onClick={() => window.open("https://www.instagram.com")}>
                            <FiInstagram />
                        </div>
                        <div className="Header-Icon" alt="instagram button" onClick={() => window.open("https://www.instagram.com")}>
                            <AiFillLinkedin />
                        </div>
                        <div className="Header-Icon" alt="mail button" onClick={() => window.open("mailto: george.afterligt@gmail.com")}>
                            <AiOutlineMail />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <div className="width-100 height-1-rem "></div> */}
        </>
    );
}

export default Header;